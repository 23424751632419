import axios from "../util/axios";

export const fetchPlans = async (user_id,plan_id) => {
  const response = await axios.get(
    `admin/v1/aligners/${user_id}/plans/${plan_id}`);
  return response.data.body;
};

export const checkPlan = async (user_id,smile_plan_id, plan_id) => {
  const response = await axios.get(
    `admin/v1/aligners/${user_id}/plans/${smile_plan_id}/check/${plan_id}`);
  if (response.data.retry) {
    throw new Error("Response flag is false");
  }
  return response.data.body;
};

export const checkout = async (user_id, plan_id) => {
  const response = await axios.post(
    `admin/v1/aligners/${user_id}/checkout`,{
        plan_id
    });
  if (response.data.retry) {
    throw new Error("Response flag is false");
  }
  return response.data.data;
};
