
import React from "react";
import { Card, CardContent, CardActions, Button, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "../../util/i18n";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "400px",
    boxShadow: theme.shadows[5],
    borderRadius: theme.spacing(1),
  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  title: {
    fontSize: "1.25rem !important",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: "60px",
    height: "4px",
    backgroundColor: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  total: {
    fontSize: "1.2rem !important",
    fontWeight: "bold",
    color: theme.palette.error.main,
  },
  price: {
    fontSize: "1.1rem !important",
    fontWeight: "bold",
  },
  description: {
    fontSize: "1rem !important",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(3),
  },
  button: {
    width: "100%",
    backgroundColor: theme.palette.error.main,
    borderRadius: "5px",
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));


export default function PricingCard({plan, t, handlePurchaseClick}) {
  const { title, description, id, currency } = plan
  const classes = useStyles();
  let isMonthly = plan.month?true:false;
  let DownPayment = plan.down_payment;
  let Monthly = plan.monthly_pay;
  let price = parseFloat(plan.total)


  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title}>{title[i18n.language]}</Typography>
          <div className={classes.divider} />
          <Typography className={classes.total}>{currency} {numberWithCommas(price)}</Typography>
          { DownPayment ?
              <div>
                <Typography variant="h6">{t('firstPayment')}</Typography>
                <Typography className={classes.price}>{currency} {numberWithCommas(DownPayment)}</Typography>
                <Typography variant="h6">{t('restPayments')}</Typography>
                <Typography className={classes.price}>{currency} {numberWithCommas(Monthly)} </Typography>
              </div>
              :
              <>
              <Typography className={classes.description}>{description[i18n.language]}</Typography>
              {isMonthly &&
              <div style={{ marginTop: "16px" }}>
                <Typography variant="h6">{t('restPayments')}</Typography>
                <Typography className={classes.price}>{currency}  {numberWithCommas(Monthly)}</Typography>
              </div>
              }
              </>
          }
          
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={()=>{handlePurchaseClick(id)}} variant="contained" className={classes.button}>Purchase</Button>
        </CardActions>
      </Card>
  );
}
