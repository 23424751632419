import React from 'react'
import { usePlans } from '../../hooks/reactQuery/useCheckoutQuery'
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../TopNav/Topnav';
import { makeStyles, Box, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import PricingCard from './PricingCard';

const useStyles = makeStyles((theme) => {
    return {
      root: {
        backgroundColor: "rgb(246, 246, 247)",
      },
      container: {
        display: "grid",
        gap: theme.spacing(3),
        maxWidth: "600px",
        margin: "auto",
        padding: theme.spacing(2),
        marginTop: "50px",
        [theme.breakpoints.up(450)]: {
          gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "1fr 1fr 1fr",
          maxWidth: "1000px",
        },
      },
      boxWrapper: {
        display: "grid",
        gap: 25,
        backgroundColor: "rgb(246, 246, 247)",
        height: "fit-content",
        padding: 20,
      },
      statusesWrapper: {
        display: "grid",
        width: "10%",
        gap: 10,
      },
      loadingBox: {
        margin: "8% auto",
        textAlign: "center",
        padding: 30,
      },
      loading: {
        textAlign: "center",
        margin: "0 auto",
        color: "#ff2c46",
      },
    }
});

function Plans() {
  let { id, planId } = useParams();
  const classes = useStyles();
  const { isLoading, error, plans } = usePlans(id, planId);
  const navigate = useNavigate();
  const { t } = useTranslation()
  const handlePurchaseClick = (plan_id) => {
    navigate(`/dashboard/users/${id}/smile-plan/${planId}/plans/${plan_id}/checkout`);
  };
  return (
    <Layout isProfile={true}>
        {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : (
        <div className={classes.container}>
            {plans && plans.map((plan, index) => (
                <PricingCard plan={plan} t={t} key={index} handlePurchaseClick={handlePurchaseClick}/>
            ))}
        </div>
    )}

    </Layout>
  )
}

export default Plans
