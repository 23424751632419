import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StripeCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();


const useStyles = makeStyles((theme) => ({
    payButton: {
        transition: 'all .3s ease',
        background: theme.palette.error.main,
        padding: (props) => props.$padding || 'initial',
        justifyContent: 'center',
        fontSize: 14,
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        borderRadius: (props) => props.$borderRadius || 'initial',
        width: (props) => props.$width || 'initial',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.7,
            background: theme.palette.error.main,
        },
        margin: (props) => props.$margin || 'auto',
        fontFamily: theme.typography.fontFamily,
        '&:disabled': {
            backgroundColor: '#e8e8e8',
            cursor: 'not-allowed',
        },
    },
}));

const PayButton = (props) => {
    const classes = useStyles(props);
    return <Button className={classes.payButton} {...props}>{props.children}</Button>;
};

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_REDIRECT,
      },
    });

    
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    setIsLoading(false);
  };

  return (
    <div style={{width: '100%'}}>
      <PaymentElement id="payment-element"/>
      <PayButton $padding={"15px"} $width={"100%"} $borderRadius={'5px'} $margin={'2em auto auto auto'}
       disabled={isLoading || !stripe || !elements} onClick={handleSubmit}>
        {t('pay-now')}
      </PayButton>
      {message ? <p className='checkout-coupon-error'>{message}</p>: null}
    </div>
  )
};

export default StripeCheckoutForm;
